<template>
  <svg
    viewBox="0 0 80 96"
    :aria-labelledby="iconName"
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="xMidYMid meet"
    role="presentation"
    :width="widthValue"
    :height="heightValue"
    :fill="iconColor"
    :style="iconStyle"
    aria-hidden="true"
  >
    <g fill="inherit">
      <path
        d="M81.01 55.43 13.15 94.81A8.73 8.73 0 0 1 0 87.37V8.62A8.73 8.73 0 0 1 13.15 1.2l67.86 39.37a8.55 8.55 0 0 1 0 14.86Z"
      />
    </g>
  </svg>
</template>
<script>
import { defineComponent } from 'vue';
import useIconSetUp from './useIconSetUp';

export default defineComponent({
  props: {
    iconName: {
      type: String,
      default: 'IconDefault',
    },
    iconColor: {
      type: String,
      default: 'currentColor',
    },
    fontSize: {
      type: String,
      default: '1rem',
    },
    width: {
      type: String,
      default: '96',
    },
    height: {
      type: String,
      default: '96',
    },
  },
  setup(props) {
    const { widthValue, heightValue, viewBoxProp, iconStyle } =
      useIconSetUp(props);

    return {
      widthValue,
      heightValue,
      viewBoxProp,
      iconStyle,
    };
  },
});
</script>
